import {LoadIndicator} from "devextreme-react/load-indicator";
import React from "react";
import {injectAppStore} from "../../../common/app.store.consumer";
import Bar from "../../../common/bar/bar";
import BarHeader from "../../../common/bar/bar.header";
import {AboveTabletLandscape, BelowTabletLandscape, Mobile} from "../../../common/responsive.queries";
import {AppStore} from "../../../stores/app.store";
import {formatForResultTables, formatForResultTablesHighLow} from "../../../utils/string.utils";

interface ResultsMicroHabitsProps {
	appStore?: AppStore;
	onSelectHabit: (selectedId?: string) => void;
}

@injectAppStore()
export default class PublicSurveyResultsMicroHabitsList extends React.Component<ResultsMicroHabitsProps> {
	onSelectHabit = (selectedId?: string) => {
		this.props.onSelectHabit(selectedId);
	};

	render() {
		if (!this.props.appStore.surveyResultsStore.isLoaded) {
			return (
				<div className="mt--preloader">
					<LoadIndicator id="large-indicator" height={60} width={60} />
				</div>
			);
		}

		const results = this.props.appStore.surveyResultsStore.results.microHabits;

		return (
			<React.Fragment>
				<div className="tbl-results-microhabits mt--mt-2">
					<div className="tbl-header tbl-row">
						<div className="cell">
							<span>{this.props.appStore.translationStore.translate("Habit")}</span>
							<span className="show-sm"></span>
						</div>
						<div className="cell">{this.props.appStore.translationStore.translate("Micro habits")}</div>
						<div className="cell">
							<BarHeader includeAll />
						</div>
						<div className="cell">
							<BelowTabletLandscape>{this.props.appStore.translationStore.translate("Avg")}</BelowTabletLandscape>
							<AboveTabletLandscape>{this.props.appStore.translationStore.translate("Average")}</AboveTabletLandscape>
						</div>
						<div className="cell">{this.props.appStore.translationStore.translate("High")}</div>
						<div className="cell">{this.props.appStore.translationStore.translate("Low")}</div>
					</div>

					{results.map(item => (
						<div key={item.habitId} className="tbl-row-group">
							{item.microHabitsDetails.map((mh, i) => (
								<div key={mh.microHabitId} className="tbl-row ">
									<div className="cell">
										{i === 0 ? (
											<div>
												<div onClick={() => this.onSelectHabit(item.habitId)} key={item.habitId}>
													{this.props.appStore.translationStore.translate(item.habitName)}
												</div>
												{/*<BarHeader addClass={"-xs"} />*/}
											</div>
										) : null}
									</div>
									<div className="cell">{this.props.appStore.translationStore.translate(mh.microHabitName)}</div>
									<div className="cell">
										<Bar key={mh.microHabitId} barValue={mh.average} bandIndex={mh.bandIndex} showAllTicks showValueOnBar />
									</div>

									<div className="cell">
										<Mobile>{this.props.appStore.translationStore.translate("Average")}: </Mobile>
										{formatForResultTables(mh.average)}
									</div>
									<div className="cell">
										<Mobile>{this.props.appStore.translationStore.translate("High")}: </Mobile>
										{formatForResultTablesHighLow(mh.highestValue)}
									</div>
									<div className="cell">
										<Mobile>{this.props.appStore.translationStore.translate("Low")}: </Mobile>
										{formatForResultTablesHighLow(mh.lowestValue)}
									</div>
								</div>
							))}
						</div>
					))}
					<div className="tbl-header tbl-row">
						<div className="cell">
							<span></span>
							<span className="show-sm"></span>
						</div>
						<div className="cell"></div>
						<div className="cell">
							<BarHeader includeAll />
						</div>
						<div className="cell"></div>
						<div className="cell"></div>
						<div className="cell"></div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
