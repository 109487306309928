import DataSource from "devextreme/data/data_source";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import moment from "moment";
import {IApiClient} from "../common/api.client";
import {
	FeedbackProvidersInfoDto,
	ParticipantSurveyStatus,
	TeamParticipantDeleteResponse,
	TeamParticipantValidationResponse,
	WebApiErrorResponse,
} from "../common/webapicall";
import {AppContextStore} from "./app.context.store";
import {ParticipantRoleStore} from "./participant.role.store";
import {StoreBase} from "./store.base";
import {UserStore} from "./user.store";
import {WebErrorMessage} from "./weberrormessage";
import {TranslationStore} from "./translation/translation.store";

export interface IParticipantImportDto {
	email?: string | null;
	firstName?: string | null;
	lastName?: string | null;
	relationship?: string | null;
}

export class FeedbackProvidersStore extends StoreBase {
	constructor(
		private apiClient: IApiClient,
		private contextStore: AppContextStore,
		private userStore: UserStore,
		private participantRoleStore: ParticipantRoleStore,
		private translation: TranslationStore,
	) {
		super(apiClient);
		makeObservable(this);
	}

	@observable FeedbackProvidersInfoDto: FeedbackProvidersInfoDto = {
		lineManager: {total: 0, min: 0, completed: 0, inProgress: 0, notStarted: 0},
		stakeholder: {total: 0, min: 0, completed: 0, inProgress: 0, notStarted: 0},
		teamLeader: {total: 0, min: 0, completed: 0, inProgress: 0, notStarted: 0},
		teamMember: {total: 0, min: 0, completed: 0, inProgress: 0, notStarted: 0},
	};

	@observable deadline?: moment.Moment | null = null;
	@observable isTeamSurveyStarted = false;
	@observable isTeamSurveyFinished = false;

	@observable isPurchashed = false;

	@observable canEditParticipantsList = false;

	@observable isCurrentUserTeamLeader = false;

	@action
	async getFeedbackProvidersInfo(teamId: string) {
		const response = await this.apiClient.teamParticipantClient.getTeamParticipantsInfo(teamId);
		runInAction(() => {
			if (response) {
				this.FeedbackProvidersInfoDto = response.feedbackProvidersInfo;

				this.deadline = response.deadline;
				this.isTeamSurveyStarted = response.isTeamSurveyStarted;
				this.isTeamSurveyFinished = response.isTeamSurveyFinished;
				this.isPurchashed = response.isPurchased;
				this.isCurrentUserTeamLeader = response.isCurrentUserTeamLeader;
			}
		});
	}

	@action
	emptyOutFeedbackProvidersInfo() {
		this.FeedbackProvidersInfoDto = {
			lineManager: {total: 0, min: 0, completed: 0, inProgress: 0, notStarted: 0},
			stakeholder: {total: 0, min: 0, completed: 0, inProgress: 0, notStarted: 0},
			teamLeader: {total: 0, min: 0, completed: 0, inProgress: 0, notStarted: 0},
			teamMember: {total: 0, min: 0, completed: 0, inProgress: 0, notStarted: 0},
		};

		this.deadline = null;
		this.isTeamSurveyStarted = false;
		this.isTeamSurveyFinished = false;
	}

	@action
	async changeTeamDeadlineForTL(teamId: string, newDeadline: moment.Moment, sendReminders: boolean) {
		await this.apiClient.teamClient.changeDeadline(teamId, {
			newDeadline: newDeadline,
			sendReminders: sendReminders,
		});
		await this.contextStore.loadContext(teamId);
		await this.participantRoleStore.getParticipantRolesForDropdown(this.contextStore.contextModel.progress.surveyCompleted);
		runInAction(() => {
			this.deadline = newDeadline;
			this.contextStore.changeTeamSurveyDeadline(newDeadline);
			if (this.isTeamSurveyFinished) {
				this.isTeamSurveyFinished = false;
				this.setCanEditParticipantsListValue(true);
			}
		});
	}

	@action
	setCanEditParticipantsListValue(value: boolean) {
		this.canEditParticipantsList = value;
	}

	getFeedbackProvidersDataSource(teamId: string) {
		return new DataSource({
			key: "id",
			load: async loadOptions => {
				const result = await this.apiClient.teamParticipantClient.getTeamParticipants(teamId, loadOptions);
				result.data.forEach(item => {
					item.isMicroHabitsShared = item.isRecommendationShared;
					item.isSummaryShared = false;
				});
				return result;
			},
			insert: async values => {
				try {
					return await this.apiClient.teamParticipantClient.addParticipantToTeam(teamId, {
						teamId: teamId,
						firstName: values.firstName,
						lastName: values.lastName,
						email: values.email,
						relationshipId: values.relationshipId,
					});
				} catch (error) {
					if (error.isWebApiErrorResponse && error.statusCode === 400) {
						throw new Error(WebErrorMessage.TranslateBadRequest(this.translation));
					} else {
						throw error;
					}
				}
			},
			update: async (key, values) => {
				try {
					return await this.apiClient.teamParticipantClient.updateTeamParticipant(key, teamId, {
						id: key,
						teamId: teamId,
						firstName: values.firstName,
						lastName: values.lastName,
						email: values.email,
						relationshipId: values.relationshipId,
					});
				} catch (error) {
					if (error instanceof WebApiErrorResponse) {
						if (error.status === 400 || error.status === 500) {
							throw new Error(WebErrorMessage.TranslateBadRequest(this.translation));
						} else {
							throw new Error(JSON.stringify(error));
						}
					} else {
						throw new Error(JSON.stringify(error));
					}
				}
			},
			remove: async key => {
				try {
					return await this.apiClient.teamParticipantClient.deleteTeamParticipant(key, teamId);
				} catch (error) {
					if (error instanceof WebApiErrorResponse) {
						if (error.status === 400) {
							const response = JSON.parse(error.response) as TeamParticipantDeleteResponse;

							switch (response) {
								case TeamParticipantDeleteResponse.TeamLeaderCannotDeleteHimself:
									throw await this.getLoggedException("You are not allowed to delete yourself from the team.", error);
								case TeamParticipantDeleteResponse.InvalidMemberCountTeamMember:
									throw await this.getLoggedException(
										"A minimum of three Team Members must be maintained in a team while a diagnostic is in progress. Please add at least one team member to delete this team member.",
										error,
									);
								case TeamParticipantDeleteResponse.InvalidMemberCountLineManager:
									throw await this.getLoggedException(
										"One Line Manager must be maintained in a team while a diagnostic is in progress. Please add a replacement line manager to delete this line manager.",
										error,
									);
								case TeamParticipantDeleteResponse.InvalidMemberCountStakeholder:
									throw await this.getLoggedException(
										"A minimum of three Stakeholders must be maintained in a team while a diagnostic is in progress. Please add at least one stakeholder to delete this stakeholder.",
										error,
									);
								case TeamParticipantDeleteResponse.InvalidMemberCount:
									throw await this.getLoggedException("Invalid member count", error);
								case TeamParticipantDeleteResponse.InvalidUser:
									throw await this.getLoggedException("Participant is not part of team", error);
								case TeamParticipantDeleteResponse.CannotDeleteLastTeamLeader:
									throw await this.getLoggedException(
										"One Team Leader must be maintained in a team while a diagnostic is in progress. Please add a replacement team leader to delete this team leader.",
										error,
									);
								default:
									throw await this.getLoggedException(response.toString(), error);
							}
						} else if (error.status === 404) {
							throw await this.getLoggedException("Participant is not part of team", error);
						}
					}

					throw await this.getLoggedException(WebErrorMessage.TranslateBadRequest(null), error);
				}
			},
			map: dataItem => {
				return {
					...dataItem,
					participantSurveyStatus: this.getParticipantSurveyStatus(dataItem.participantSurveyStatus),
					relationship: this.translation.translate(dataItem.relationship ? `${dataItem.relationship} (${dataItem.relationshipId === "SH" ? "S" : dataItem.relationshipId})` : dataItem.relationship),
				};
			},
		});
	}

	getAdminFeedbackProvidersDataSource(teamId: string) {
		return new DataSource({
			key: "id",
			load: async loadOptions => {
				return await this.apiClient.teamParticipantClient.getTeamParticipants(teamId, loadOptions);
			},
			insert: async values => {
				try {
					return await this.apiClient.teamParticipantClient.addParticipantToTeam(teamId, {
						teamId: teamId,
						firstName: values.firstName,
						lastName: values.lastName,
						email: values.email,
						relationshipId: values.relationshipId,
					});
				} catch (error) {
					if (error.isWebApiErrorResponse && error.statusCode === 400) {
						throw new Error(WebErrorMessage.TranslateBadRequest(this.translation));
					} else {
						throw error;
					}
				}
			},
			update: async (key, values) => {
				try {
					return this.apiClient.teamParticipantClient.updateTeamParticipant(key, teamId, {
						id: key,
						teamId: teamId,
						firstName: values.firstName,
						lastName: values.lastName,
						email: values.email,
						relationshipId: values.relationshipId,
					});
				} catch (error) {
					if (error.isWebApiErrorResponse && error.statusCode === 400) {
						throw new Error(WebErrorMessage.TranslateBadRequest(this.translation));
					} else {
						throw error;
					}
				}
			},
			remove: async key => {
				try {
					return await this.apiClient.teamParticipantClient.deleteTeamParticipant(key, teamId);
				} catch (error) {
					if (error.isWebApiErrorResponse && error.statusCode === 400) {
						throw new Error(WebErrorMessage.TranslateBadRequest(this.translation));
					} else {
						throw error;
					}
				}
			},
			map: dataItem => {
				return {
					...dataItem,
					participantSurveyStatus: this.getParticipantSurveyStatus(dataItem.participantSurveyStatus),
				};
			},
		});
	}

	@computed get isSHLimit(): boolean {
		return this.FeedbackProvidersInfoDto?.stakeholder.total >= this.FeedbackProvidersInfoDto?.stakeholder.min;
	}

	@computed get isLMLimit(): boolean {
		return this.FeedbackProvidersInfoDto?.lineManager.total >= this.FeedbackProvidersInfoDto?.lineManager.min;
	}

	@computed get isTMLimit(): boolean {
		return this.FeedbackProvidersInfoDto?.teamMember.total >= this.FeedbackProvidersInfoDto?.teamMember.min;
	}

	@computed get canDiagnosticStart(): boolean {
		if (
			this.contextStore.contextModel.progress.isSurveyStarted &&
			(!this.contextStore.contextModel.progress.isSurveyRunning || !this.contextStore.contextModel.progress.isFinished)
		) {
			return false;
		}

		if (this.contextStore.contextModel.teamModel.teamSurveyDeadline && this.contextStore.contextModel.teamModel.teamSurveyDeadline < moment()) {
			return false;
		}

		return this.isSHLimit && this.isLMLimit && this.isTMLimit;
	}

	@computed get isTeamSurveyDeadlinePassed(): boolean {
		if (!this.contextStore.contextModel.teamModel.teamSurveyDeadline) {
			return false;
		}
		const isPassed = this.contextStore.contextModel.teamModel.teamSurveyDeadline < moment();
		return isPassed;
	}

	isTheLoggedInUsersRow = (userId: string) => {
		return !this.contextStore.contextModel.progress.isSurveyStarted && this.userStore.userId === userId;
	};

	canBeDeleted = (relationshipId: string): boolean => {
		if (!relationshipId) return false;

		switch (relationshipId.trim().toUpperCase()) {
			case "LM":
				return this.FeedbackProvidersInfoDto?.lineManager.total > this.FeedbackProvidersInfoDto?.lineManager.min;
			case "TM":
				return this.FeedbackProvidersInfoDto?.teamMember.total > this.FeedbackProvidersInfoDto?.teamMember.min;
			case "SH":
				return this.FeedbackProvidersInfoDto?.stakeholder.total > this.FeedbackProvidersInfoDto?.stakeholder.min;
			case "TL":
				return this.FeedbackProvidersInfoDto?.teamLeader.total > this.FeedbackProvidersInfoDto?.teamLeader.min;
		}

		return false;
	};

	async isEmailUnique(teamId: string, email: string, participantId?: string) {
		return new Promise(async (resolve, reject) => {
			if (email !== "") {
				const dto = {participantId, email};
				try {
					await this.apiClient.teamParticipantClient.validateParticipant(teamId, dto);
				} catch (error) {
					if (error instanceof WebApiErrorResponse) {
						let reason = "Unknown";
						if (error.status === 400) {
							const response = JSON.parse(error.response) as TeamParticipantValidationResponse;

							if (response === TeamParticipantValidationResponse.EmailBelongsToAdmin) {
								reason = this.translation.translate("Admin users cannot be added to the team diagnostic");
							} else if (response === TeamParticipantValidationResponse.EmailNotUnique) {
								reason = this.translation.translate("Email address is not unique");
							} else {
								reason = response.toString();
							}
						}
						reject(reason);
					}

					reject(JSON.stringify(error));
				}
				resolve(true);
			}
		});
	}

	async ImportParticipants(teamId: string, participants: IParticipantImportDto[]) {
		await this.apiClient.teamParticipantClient.importParticipantToTeam(
			teamId,
			participants.map(s => ({email: s.email, firstName: s.firstName, lastName: s.lastName, relationshipId: s.relationship, teamId: teamId})),
		);
	}

	async startDiagnostic(teamId: string): Promise<string> {
		const canStart = this.canDiagnosticStart;
		const isSurveyRunning = this.contextStore.contextModel.progress.isSurveyRunning;
		if (canStart && !isSurveyRunning) {
			const participantSurveyId = await this.apiClient.teamSurveyClient.startTeamSurvey(teamId);
			return participantSurveyId ?? "";
		}
	}

	private getParticipantSurveyStatus(status: ParticipantSurveyStatus): string {
		switch (status) {
			case ParticipantSurveyStatus.NA:
				return this.translation.translate("Not Started");
			case ParticipantSurveyStatus.NotVisited:
				return this.translation.translate("Not Visited");
			case ParticipantSurveyStatus.InProgress:
				return this.translation.translate("In Progress");
			case ParticipantSurveyStatus.Completed:
				return this.translation.translate("Completed");
			case ParticipantSurveyStatus.NotSubmitted:
				return this.translation.translate("Not Submitted");
			case ParticipantSurveyStatus.PostDiagnostic:
				return this.translation.translate("Post Diagnostic");
			default:
				return "";
		}
	}
}
