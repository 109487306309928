import Two from "two.js";
import {action, computed, makeObservable, observable} from "mobx";
import {ISummaryRadarVisibility} from "../public/survey-results/models/summary.radar.visibility";
import {SummaryDetails, SurveyResultBandIndex} from "../common/webapicall";
import { TranslationStore } from "./translation/translation.store";

export class SurveyResultsRadarStore {
	readonly max = 10;
	readonly ngon = 8;
	readonly size = {width: 500, height: 500};

	@observable two: Two;
	@observable private triangleGroups: Two.Group[];
	@observable radarVisibility: ISummaryRadarVisibility = {
		TMVisible: true,
		TVisible: true,
		SVisible: true,
		MVisible: true,
		OVisible: true,
	};

	constructor(private translation: TranslationStore) {
		makeObservable(this);
	}

	@computed get inc(): number {
		return 360 / this.ngon;
	}

	@computed get centerX(): number {
		return this.size.width / 2;
	}

	@computed get centerY(): number {
		return this.size.height / 2;
	}

	@computed get radius(): number {
		return this.size.width / 2 - 20;
	}

	@action
	toggleVisibility(visibilityName: string, results: SummaryDetails[]) {
		this.radarVisibility[visibilityName] = !this.radarVisibility[visibilityName];
		this.getCharts(results);
		this.two.update();
	}

	@action
	getOctagon() {
		let i = 0;
		for (let r = 0; r <= this.radius; r += this.radius / this.max) {
			if (i !== 0 && i !== Math.round(this.max / 10) && i !== Math.round(this.max / 3) && i !== Math.round(this.max / 2)) {
				let polygonStroke = this.two.makePolygon(this.centerX, this.centerY, r, this.ngon);
				i === 10 ? (polygonStroke.stroke = "#697378") : (polygonStroke.stroke = "#D8DEE3");
				polygonStroke.fill = "transparent";
			}
			i++;
		}
	}

	@action
	getSpokes(args: string[]) {
		args.forEach((e, i) => {
			let spokes = this.two.makeLine(
				this.centerX,
				this.centerY,
				this.centerX + Math.cos(this.degToRad(i * this.inc - 112.5)) * this.radius,
				this.centerY + Math.sin(this.degToRad(i * this.inc - 112.5)) * this.radius,
			);

			spokes.stroke = "#D8DEE3";
			spokes.linewidth = 2;
			spokes.dashes = [3, 3];
			let txt = this.two.makeText(
				e,
				this.centerX + Math.cos(this.degToRad(i * this.inc - 90)) * this.radius * 1.05,
				this.centerY + Math.sin(this.degToRad(i * this.inc - 90)) * this.radius * 1.05,
				{},
			);
			txt.family = this.translation.getLanguageCode==="ar" ? "'Almarai', Arial" : "'Open-Sans', sans-serif";
			txt.size = this.translation.getLanguageCode==="ar" ? 18: 14;
			txt.weight = "600";
			txt.fill = "#0F3250";
			txt.rotation = i > 2 && i < 6 ? i * this.degToRad(this.inc) + this.degToRad(180) : i * this.degToRad(this.inc);
		});
	}

	@action
	getCharts(results: SummaryDetails[]) {
		if (this.triangleGroups) {
			this.two.remove(this.triangleGroups);
		}

		const triCount = this.getVisibleCount();
		let triangleGroups = [];
		results.forEach((el, sect) => {
			let triangleGroup = new Two.Group();
			let visibleSeries = [];

			if (this.radarVisibility.TMVisible) {
				visibleSeries.push({label: "TL", roleId: "TL"});
			}
			if (this.radarVisibility.TVisible) {
				visibleSeries.push({label: "T", roleId: "TM"});
			}
			if (this.radarVisibility.SVisible) {
				visibleSeries.push({label: "S", roleId: "SH"});
			}
			if (this.radarVisibility.MVisible) {
				visibleSeries.push({label: "LM", roleId: "LM"});
			}
			if (this.radarVisibility.OVisible) {
				visibleSeries.push({label: "O", roleId: "O"});
			}

			for (let i = 0; i < triCount; i++) {
				const role = visibleSeries[i];
				if (role.roleId === "O") {
					this.addTriangleGroup(triangleGroup, el.overallAverage, el.overallBandIndex, role.label, this.radius, triCount, i);
				} else {
					const roleAverage = el.roleAverages.find(r => r.participantRoleId === role.roleId);
					this.addTriangleGroup(triangleGroup, roleAverage.value, roleAverage.bandIndex, role.label, this.radius, triCount, i);
				}
			}

			triangleGroup.rotation = this.degToRad(sect * 45);
			triangleGroup.translation.set(this.centerX, this.centerY);
			triangleGroup.id = "triangle-group" + sect;
			triangleGroups.push(triangleGroup);
			this.two.add(triangleGroup);
		});

		this.triangleGroups = triangleGroups;
	}

	getArgs(results: SummaryDetails[]): string[] {
		return results.map((val): string => {
			return val.habitName.toUpperCase();
		});
	}

	private addTriangleGroup(
		triangleGroup: Two.Group,
		value: number,
		bandIndex: SurveyResultBandIndex,
		barLabelName: string,
		radius: number,
		triCount: number,
		position: number,
	) {
		let r = (value / this.max) * radius;
		//length of octagon SIDE for the given r
		let s = 2 * Math.sin(this.degToRad(360 / this.ngon / 2)) * r;
		//GAP
		let g = Math.min(s / triCount, s / 4);
		let x = g + Math.cos(this.degToRad(-112.5)) * r;
		let y = Math.sin(this.degToRad(-112.5)) * r;
		// bar WIDTH
		let w = (s - 2 * g) / triCount;
		//distance between bars
		x += position * w;
		let x2 = x + w;
		let y2 = y;
		let triangleContainer = new Two.Group();
		let triangle = this.two.makePath(
			[
				new Two.Anchor(0, 0, 0, 0, 0, 0, Two.Commands.move),
				new Two.Anchor(x, y, 0, 0, 0, 0, Two.Commands.line),
				new Two.Anchor(x2, y2, 0, 0, 0, 0, Two.Commands.line),
			],
			true,
		);

		//BAR LABELS
		let barLabel = new Two.Text(barLabelName, +x + w / 2, y - 8);
		barLabel.size = 10;
		barLabel.weight = 400;
		if (s / 100 > 0.5) {
			barLabel.scale = this.clamp(s / 100, 0.7, 1);
		} else {
			barLabel.opacity = 0;
		}

		triangle.stroke = "white";
		triangle.linewidth = 1;
		triangle.cap = "square";
		triangle.fill = this.bandIndexToColor(bandIndex);

		triangleContainer.add(triangle);
		triangleGroup.add(triangleContainer, barLabel);
	}

	private bandIndexToColor(bandIndex: SurveyResultBandIndex): string {
		switch (bandIndex) {
			case SurveyResultBandIndex.Band1:
				//return "hsl(100deg 50% 50% / 85%)"; //green
				return "#87C367";
			case SurveyResultBandIndex.Band2:
				//return "hsl(50deg 100% 47% / 85%)"; //yellow
				return "#FAD224";
			case SurveyResultBandIndex.Band3:
				//return "hsl(35deg 95% 50% / 85%)"; //orange
				return "#FEAD28";
			case SurveyResultBandIndex.Band4:
				//return "hsl(0deg 90% 55% / 85%)"; //red
				return "#FE6056";
		}

		return "lightgrey";
	}

	private clamp(num: number, min: number, max: number): number {
		return num <= min ? min : num >= max ? max : num;
	}

	private degToRad(degrees: number): number {
		const pi = Math.PI;
		return degrees * (pi / 180);
	}

	private getVisibleCount(): number {
		const keys = Object.keys(this.radarVisibility);
		return keys.filter(key => this.radarVisibility[key]).length;
	}
}
