import React from "react";
import {injectAppStore} from "../app.store.consumer";
import {AppStore} from "../../stores/app.store";
import hu from "../../assets/img/howto_illustration.hu-HU.png";
import en from "../../assets/img/howto_illustration.en-GB.png";
import de from "../../assets/img/howto_illustration.de-DE.png";
import ar from "../../assets/img/howto_illustration.ar.png";
import TImage, {IImgProps} from "./timage";


interface Props extends IImgProps {
    appStore?: AppStore;
}

const HowtoIllustration: React.FC<Props> = injectAppStore()(props  => {

    return <TImage src={{
        "de-DE": de,
        "en-GB": en,
        "hu-HU": hu,
        "ar": ar
    }} {...props} />
});

export default HowtoIllustration;

