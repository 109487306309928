import { useMediaQuery } from 'react-responsive'

export const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1280 })
    return isDesktop ? children : null
}

export const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 800, maxWidth: 1024 })
    return isTablet ? children : null
}

export const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 480 })
    return isMobile ? children : null
}

export const BelowTablet = ({ children }) => {
    const isBelowTablet = useMediaQuery({ maxWidth: 799 })
    return isBelowTablet ? children : null
}

export const AboveTablet = ({ children }) => {
    const isAboveTablet = useMediaQuery({ minWidth: 800 })
    return isAboveTablet ? children : null
}

export const BelowTabletLandscape = ({ children }) => {
    const isBelowTablet = useMediaQuery({ maxWidth: 1024 })
    return isBelowTablet ? children : null
}

export const AboveTabletLandscape = ({ children }) => {
    const isAboveTablet = useMediaQuery({ minWidth: 1025 })
    return isAboveTablet ? children : null
}

//export const Default = ({ children }) => {
//    const isNotMobile = useMediaQuery({ minWidth: 768 })
//    return isNotMobile ? children : null
//}