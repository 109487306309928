import {LoadIndicator} from "devextreme-react/load-indicator";
import React from "react";
import {injectAppStore} from "../../../common/app.store.consumer";
import Bar from "../../../common/bar/bar";
import BarHeader from "../../../common/bar/bar.header";
import {AppStore} from "../../../stores/app.store";
import {formatForResultTables} from "../../../utils/string.utils";

interface ResultsSummaryBarsProps {
	appStore?: AppStore;
}

@injectAppStore()
export default class PublicSurveyResultsSummaryBars extends React.Component<ResultsSummaryBarsProps> {
	render() {
		if (this.props.appStore.surveyResultsStore.getOrderedSummaryListByParticipantRoleAverage.length === 0) {
			return (
				<div className="mt--preloader">
					<LoadIndicator id="large-indicator" height={60} width={60} />
				</div>
			);
		}

		const results = this.props.appStore.surveyResultsStore.getOrderedSummaryListByParticipantRoleAverage;
		return (
			<React.Fragment>
				<div className="mt--area-wrap mt--fill-white">
					<div className="mt--layout">
						<div className="mt--content mt--pt-0 ">
							<div className="tbl-results-habits">
								<div className="tbl-header tbl-row">
									<div className="cell">
										<span>{this.props.appStore.translationStore.translate("Habit")}</span>
									</div>
									<div className="cell">{this.props.appStore.translationStore.translate("Feedback provider")}</div>
									<div className="cell">
										<BarHeader includeAll />
									</div>
									<div className="cell">{this.props.appStore.translationStore.translate("Score")}</div>
									<div className="cell">{this.props.appStore.translationStore.translate("Overall")}</div>
								</div>
								{results.map(item => (
									<div key={item.habitId} className="tbl-row-group">
										{item.roleAverages.map((role, i) => (
											<div key={role.participantRoleId} className="tbl-row">
												<div className="cell">
													{i === 0 ? this.props.appStore.translationStore.translate(item.habitName) : null}
													<div className="oa-avg">{item.overallAverage.toFixed(1)}</div>
												</div>
												<div className="cell">{this.props.appStore.surveyResultsStore.getRoleNameByRoleId(role.participantRoleId)}</div>
												<div className="cell">
													<Bar barValue={role.value} bandIndex={role.bandIndex} showAllTicks showValueOnBar />
												</div>
												<div className="cell">
													<div>{formatForResultTables(role.value)}</div>
												</div>
												<div className="cell">{i === 0 ? formatForResultTables(item.overallAverage) : null}</div>
											</div>
										))}
									</div>
								))}
								<div className="tbl-header tbl-row">
									<div className="cell">
										<span></span>
									</div>
									<div className="cell"></div>
									<div className="cell">
										<BarHeader includeAll />
									</div>
									<div className="cell"></div>
									<div className="cell"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
